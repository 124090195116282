import React from "react"

import { FaRegEnvelope, FaGithub, FaLinkedinIn } from "react-icons/fa"

const Socials = () => (
    <div class="flex flex-row justify-center mt-5">
        <div class="bg-custom-gray rounded-full h-10 w-10 flex items-center justify-center m-1">
            <a class="hover:text-primary-link" href="mailto:hello@normade.dev?Subject=Hello" target="_top" title="Email to Norma Driske">
                <FaRegEnvelope />
            </a>
        </div>
        <div class="bg-custom-gray rounded-full h-10 w-10 flex items-center justify-center m-1">
            <a class="hover:text-primary-link" href="https://github.com/normade" target="_top" title="GitHub of Norma Driske">
                <FaGithub />
            </a>
        </div>
        <div class="bg-custom-gray rounded-full h-10 w-10 flex items-center justify-center m-1">
            <a class="hover:text-primary-link" href="https://linkedin.com/in/norma-driske/" target="_top" title="LinkedIn contact of Norma Driske">
                <FaLinkedinIn />
            </a>
        </div>
    </div>
)

export default Socials
