import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Socials from "./socials"
import Menu from "./menu"

const Sidebar = () => (
    <div className="">
        <h1 className="text-primary text-right text-3xl">Norma Driske</h1>
        <p className="text-primary text-right text-sm">Software Engineer, IT Consultant</p>
        <div className="md:pl-10 mt-5">
            <StaticImage
                src="../images/norma.jpg"
                alt="Norma in the mountains"
                className="rounded-md"
            />
            <Socials />
            <Menu />
        </div>
    </div>
)

export default Sidebar
