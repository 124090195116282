import * as React from "react"
import PropTypes from "prop-types"
import Sidebar from "./sidebar"
import "./layout.css"

const Layout = ({ children }) => {

    return (
        <div className="container my-7 mx-auto px-10">
            <div className="flex flex-wrap overflow-hidden">
                <aside className="my-2 px-2 w-full overflow-hidden sm:my-1 sm:px-1 sm:w-full md:px-20 lg:order-2 lg:w-1/4 lg:px-0">
                    <Sidebar />
                </aside>
                <main className="my-2 w-full overflow-hidden sm:my-1 md:px-1 sm:w-full lg:order-1 lg:w-3/4">
                    {children}
                </main>
            </div>
            <footer className="text-xs text-primary text-right mt-5 md:mt-10">
                © {new Date().getFullYear()}, built with <a className="underline" href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">Gatsby</a> &amp; <a className="underline" href="https://tailwindcss.com/" target="_blank" rel="noreferrer">tailwindcss</a>
            </footer>
        </div>

    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout